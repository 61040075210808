<template>
  <div class="home">
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <div>

      <div class="container">
        <div class="hmlf">

          <p class="hmtitle">健康管理师</p>
          <p class="hmtxt">瑞小安健康管理师均具备完整知识体系，掌握健康评估、干预、教育、调查、沟通等多项技能。</p>
          <p class="hmtxt">对饮食、运动、睡眠、久坐等生活方式提供科学建议、智能监测、打卡积分和专业指导，并具有网络社交属性，可以通过群组功能开展健康比赛，从而实现有效的健康管理与慢性病管理。</p>

        </div>
        <div>
          <img :src="require('../assets/hm.png')" width="560" height="300">
        </div>


        <div class="hmbg">
          <img :src="require('../assets/hm_bg.png')" width="1300" height="420">
        </div>
      </div>

      <div class="container">
        <div>
          <img :src="require('../assets/suipeople.png')" width="420" height="530">
        </div>
        <div class="suirt">
          <div class="hmtitle" style="margin-bottom:35px;margin-top: 20px;">适宜人群</div>
          <div class="suiitem">
            <img :src="require('../assets/sui1.png')" width="72" height="72">
            <div class="suiitemtxt">
              <div class="itemtitle">慢性病早中期患者：</div>
              <div class="itemtxt">高血压、高血糖、高尿酸、高血脂、脂肪肝等</div>
            </div>
          </div>
          <div class="suiitem">
            <img :src="require('../assets/sui2.png')" width="72" height="72">
            <div class="suiitemtxt">
              <div class="itemtitle">亚健康人群：</div>
              <div class="itemtxt">肥胖、易疲乏、失眠、机能下降、功能紊乱等</div>
            </div>
          </div>
          <div class="suiitem">
            <img :src="require('../assets/sui3.png')" width="72" height="72">
            <div class="suiitemtxt">
              <div class="itemtitle">生活方式不良者：</div>
              <div class="itemtxt">缺乏运动、饮食、睡眠不科学、久坐等</div>
            </div>
          </div>
          <div class="suiitem">
            <img :src="require('../assets/sui4.png')" width="72" height="72">
            <div class="suiitemtxt">
              <div class="itemtitle">备孕人群：</div>
              <div class="itemtxt">调整身体状态、科学备孕</div>
            </div>
          </div>
          <div class="suiitem">
            <img :src="require('../assets/sui5.png')" width="72" height="72">
            <div class="suiitemtxt">
              <div class="itemtitle">热爱健康人士：</div>
              <div class="itemtxt">学习健康知识、体验健康生活</div>
            </div>
          </div>
        </div>
        <div class="suibg">
          <img :src="require('../assets/hm_bg.png')" width="1200" height="440">
        </div>
      </div>

      <div>
        <div class="hmtitle ttsure">数字化健康管理</div>
        <div class="numct">
          <img :src="require('../assets/num1.png')" width="700" height="720" style="margin-left: -200px;">

          <div class="grid-container">
            <div class="grid-item">
              <el-card class="numcard">
                <img :src="require('../assets/num2.png')" width="60" height="60">
                <p class="numcardtxt">瑞小安会员享有个性化膳食方案制定与指导服务，支持拍照上传饮食图片，健康管理师人工评审、一对一指导。</p>
              </el-card>
            </div>
            <div class="grid-item">
              <el-card class="numcard">
                <img :src="require('../assets/num3.png')" width="60" height="60">
                <p class="numcardtxt">瑞小安APP会根据您的基础档案资料，智能生成个性化运动建议，包括运动频次、靶心率值和运动时长，会员更有健康管理师人工复核。</p>
              </el-card>
            </div>
            <div class="grid-item">
              <el-card class="numcard">
                <img :src="require('../assets/num4.png')" width="60" height="60">
                <p class="numcardtxt">瑞小安健康管理手环提供睡眠监测功能。睡眠时佩戴，可记录详细的睡眠数据，如深睡、浅睡、清醒等，连接瑞小安APP即可同步数据。</p>
              </el-card>
            </div>
            <div class="grid-item">
              <el-card class="numcard">
                <img :src="require('../assets/num5.png')" width="60" height="60">
                <p class="numcardtxt">瑞小安要求在工作时间段内，每小时步行量大于200步。久坐时瑞小安手环/手表即会震动提醒。</p>
              </el-card>
            </div>
          </div>
        </div>

      </div>
      <div class="premg">
        <div class="hmtitle" style="margin-bottom: 20px;margin-top: 90px;">页面预览</div>
        <div>
          <img :src="require('../assets/preview.png')" width="1080" height="580" style="margin-left:-20px">
        </div>
      </div>
    </div>

  </div>
</template>
<style> .premg {
   margin-top: 30px;
   margin-bottom: 80px;
 }

 .numcard {
   width: 200px;
   height: 300px;
   padding: 10px;
 }

 .numcardtxt {
   font-size: 16px;
   line-height: 1.5;
   color: gray;
   letter-spacing: 1px;
   text-align: justify;
 }

 .grid-container {
   display: grid;
   grid-template-columns: auto auto;
   grid-template-rows: auto auto;
   margin-left: -30px;
   /* 网格间隙 */
 }

 .grid-item {
   padding: 20px;
   text-align: center;
 }

 .suiitem {
   display: flex;
   align-items: center;
   margin-bottom: 25px;
 }

 .suiitemtxt {
   margin-left: 22px;
 }

 .itemtitle {
   color: gray;
   font-size: 20px;
   margin-bottom: 5px;
 }

 .itemtxt {
   color: gray;
   font-size: 16px;
 }

 .suirt {
   width: 560px;
   margin-left: 140px;
 }

 .suibg {
   position: absolute;
   right: -180px;
   bottom: -70px;
   z-index: -1;
 }

 .home {
   text-align: left;
   display: flex;
   justify-content: center;
   align-items: center;
 }

 .container {
   position: relative;
   display: flex;
   align-items: center;
   margin: 150px auto;
   justify-content: center;
 }

 .numct {
   position: relative;
   display: flex;
   align-items: center;
   justify-content: center;
 }

 .hmbg {
   position: absolute;
   left: -300px;
   bottom: -70px;
   z-index: -1;
 }

 .hmlf {
   width: 480px;
   margin-right: 60px;
 }

 .hmtitle {
   font-size: 42px;
   color: rgb(110, 110, 110);
   margin-top: 10px;
 }

 .hmtxt {
   font-size: 16px;
   line-height: 2;
   color: gray;
   letter-spacing: 3px;
 }
</style>
<script>
// import HelloWorld from '@/components/HelloWorld.vue'

// export default {
//   name: 'Home',
//   components: {
//     HelloWorld
//   }
// }
</script>
