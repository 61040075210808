<template>
  <div class="banner">

    <div class="nav">
      <img src="./assets/logo_top.png" width="60" height="60" alt="瑞小安健康管理" style="margin-left:-8px">
      <span style="font-size:18px;">To meet the better of yourself</span>
      <div style="margin-left: auto;"><router-link to="/"><el-button type="success"
            style="font-size:18px; padding:10px 20px;background-color: #15BC7C;border-color: #15BC7C;">首页</el-button></router-link>
      </div>
    </div>
    <div>
      <div class="title">瑞小安健康管理</div>
      <div class="titlemid">遇见更好的自己</div>
      <p class="titletxt">健康管理师服务+APP+健康监测 设备（智能手表）</p>
      <el-button type="success"
        style="font-size:18px;padding:10px 20px; background-color: #15BC7C;border-color: #15BC7C;">立即体验</el-button>
    </div>
    <div class="ctus">
      <el-form ref="form" :model="form" label-width="80px">
        <h1 style="font-size: 22px;color: #15BC7C;">联系我们</h1>

        <div style="margin-top: 20px;">
          <el-input v-model="form.name" placeholder="您的称呼"></el-input>
        </div>
        <div style="margin-top: 20px;">
          <el-input v-model="form.mail" placeholder="您的邮箱"></el-input>
        </div>
        <div style="margin-top: 20px; ">
          <el-input v-model="form.phone" placeholder="您的电话"></el-input>
        </div>

        <el-button type="success" @click="onSubmit"
          style="background-color: #15BC7C; width: 100%;padding:20px;font-size: 20px;border-radius: 10px;margin-top: 20px;">确定</el-button>
      </el-form>
    </div>

  </div>

  <div class="serverintro">
    <div class="introtxt">
      瑞小安健康管理是依据健康管理学经典理论，配置健康管理师专人服务，搭配自主开发的智能健康手表与应用，以纯粹的生活方式干预方式，管理饮食、运动、睡眠、久坐和健康教育，并给予健康行为奖励，较好的解决了专业指导问题、数据同步与沟通问题、患者依从性问题等。
    </div>
  </div>
  <div class="cardbg">
    <el-card class="card">
      <img :src="require('./assets/product1.png')" width="240" height="120"  style="margin-left:-30px;margin-top: 30px;margin-bottom: 20px;">
      <h3 class="cardtitle">21天专属健康管理师</h3>
      <p class="cardtxt">精心带教21天，管理饮食、运动、睡眠、久坐、健康教育。提供方案制定、行为督导、健康评估等专业服务。</p>
    </el-card>
    <el-card class="card">
      <img :src="require('./assets/product2.png')" width="160" height="160" class="mgt10">
      <h3 class="cardtitle">瑞小安健康管理手册</h3>
      <p class="cardtxt">瑞小安健康管理：纯粹管理饮食、运动、睡眠、久坐等重要生活方式，旨在让您受益终生。</p>
    </el-card>
    <el-card class="card">
      <img :src="require('./assets/product3.png')" width="180" height="160" class="mgt10">
      <h3 class="cardtitle">瑞小安S20手表</h3>
      <p class="cardtxt">主要实现：久坐时间管理、入睡时间与睡眠时长管理、靶心率运动监测与步数管理、心率预警。</p>
    </el-card>
    <el-card class="card">
      <img :src="require('./assets/product4.png')" width="160" height="160" class="mgt10">
      <h3 class="cardtitle">瑞小安R10体脂秤</h3>
      <p class="cardtxt">实现体脂相关指标的精准测量与数据实时同步，包括体重、脂肪率、BMI指数等15项数据测量。</p>
    </el-card>
    <el-card class="card">
      <img :src="require('./assets/product5.png')" width="160" height="160" class="mgt10">
      <h3 class="cardtitle">血糖、血脂尿酸检测仪</h3>
      <p class="cardtxt">精准测量血糖、总胆固醇、尿酸三项指标，独立试纸一机三测，数据实时同步。</p>
    </el-card>
  </div>
  <router-view />
  <div class="footer">
    <div class="footerct">
      <div style="display: flex;margin-left: 100px;">
        <div>
          <p class="fttxt">扫码下载</p>
          <img :src="require('./assets/dl.png')" width="120" height="120" class="qrc">
        </div>
        <div style="margin-left: 30px;">
          <p class="fttxt">扫码购买</p>
          <img :src="require('./assets/shop.png')" width="120" height="120" class="qrc">
        </div>
      </div>
      <div class="footerrt">
        <div class="fttitle">联系方式</div>
        <div class="ftitem">
          <img :src="require('./assets/footer1.png')" width="32" height="32">
          <div class="fttxt">电话：0755-86165800</div>
        </div>
        <div class="ftitem">
          <img :src="require('./assets/footer2.png')" width="32" height="32">
          <div class="fttxt">邮箱：xavier@ryan-doctor.com</div>
        </div>
        <div class="ftitem">
          <img :src="require('./assets/footer3.png')" width="32" height="32">
          <div class="fttxt">地址：深圳国际创新谷三期七栋A座201</div>
        </div>
        <div style="margin-left: 6px;margin-top: 8px;font-size: 14px;">
          版权所有 ©2024 | 瑞小安科技（深圳）有限公司 |
          <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" class="info">粤ICP备2020074210号-1</a>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { ElMessage } from "element-plus";
export default {
  data() {
    return {
      form: {
        name: "",
        phone: "",
        mail: "",
      },
    };
  },
  methods: {
    onSubmit() {
      if (this.$data.form.name == "") {
        ElMessage.warning({
          message: "请填写称呼",
          type: "warning",
        });
        return;
      }
      if (this.$data.form.phone == "") {
        ElMessage.warning({
          message: "请填写电话",
          type: "warning",
        });
        return;
      }
      if (this.$data.form.mail == "") {
        ElMessage.warning({
          message: "请填写邮箱",
          type: "warning",
        });
        return;
      }
      setTimeout(() => {
        ElMessage.success({
          message: "提交成功",
          type: "success",
        });
      }, 600);
    },
  },
};
</script>
<style>
.ctus { 
  position: absolute;
  right: 200px;
  bottom: 90px;
  width: 300px;
  height: 420px;
  box-sizing: border-box;
  background-color: white;
  border: 2px solid white;
  box-shadow: 0px 0px 40px 0px rgba(21, 188, 124, 0.25);
  /* 阴影 */
  border-radius: 20px;
  padding: 30px;
  z-index: 9999;
}

.el-input .el-input__inner {

  font-size: 18px;
  padding: 25px 20px;
  border-radius: 10px;

}

.qrc {
  padding: 10px;
  border: 2px solid #15BC7C;
  border-radius: 20px;
}

.footerct {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.footerrt {
  margin-left: 180px;
  text-align: left;
}

.ftitem {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.fttitle {
  font-size: 26px;
  margin-bottom: 15px;
  margin-top: 5px;
}

.fttxt {
  font-size: 17px;
}

.cardbg {
  margin-top: 120px;
}

.card {
  width: 220px;
  height: 360px;
  padding: 10px;
  margin-right: 20px;
  display: inline-block;
}

.cardtitle {
  font-size: 16px;
  color: #15BC7C;
  margin: 20px 0;
}

.cardtxt {
  font-size: 14px;
  line-height:1.5;
  color: gray; 
  text-align:justify;
}

.mgt10 {
  margin-top: 10px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.nav {
  display: flex;
  align-items: center;
  /* 垂直居中 */
  /* 其他样式 */
}

.title {

  font-size: 82px;
  letter-spacing: 6px;
  font-weight: 600;
  margin-top: 160px;
  margin-bottom: 14px;
}

.titlemid {
  font-size: 42px;
  letter-spacing: 6px;
}

.titletxt {
  font-size: 18px;
  margin-bottom: 120px;
}

.info {
  text-decoration: none;
  color: white;
}

.banner {
  /* background: url("./assets/banner.png") no-repeat; */
  background-image: url('./assets/banner.png');
  /* 设置背景图片 */
  background-size: cover;
  /* 使背景图片全景呈现 */
  background-position: center;
  /* 将背景图片居中 */
  padding: 20px 200px 80px 200px;
  height: 620px;
  color: white;
  text-align: left;
}

.serverintro {
  background-image: url('./assets/server_wrap.png');
  /* 设置背景图片 */
  background-size: cover;
  /* 使背景图片全景呈现 */
  background-position: center;
  /* 将背景图片居中 */

  height:980px;
  margin-top: 80px;
}

.introtxt {
  position: relative;
  width: 860px;
  font-size: 18px;
  line-height: 2.5;
  top: 520px;
  left: 300px;
  background-color: rgba(0, 0, 0, 0.29);
  /* 其他样式 */
  padding: 40px;
  color: white;
  text-align: left;
}

.footer {
  height: 200px;
  background-image: url('./assets/footerbg.png');
  /* 设置背景图片 */
  background-size: cover;
  /* 使背景图片全景呈现 */
  background-position: center;
  /* 将背景图片居中 */
  padding: 100px;
  font-size: 18px;
  color: white;
}</style>
